<template>
  <div class="position-relative pb-1">
    <AdditionForm/>
  </div>
</template>

<script>
import AdditionForm from "@/views/components/additions/AdditionForm";
export default {
  name: "Edit",
  components: {AdditionForm}
}
</script>

<style scoped>

</style>
